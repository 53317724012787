import React, {useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import VoucherPanel from "../ViewVoucher/VoucherPanel";
import {EntityTypes, UserTypes} from "../../../common/Constants";
import { CircularProgress, Drawer, Fab, Typography } from "@mui/material";
import CreateVoucher from "../CreateVoucher";
import {EmptySearchOption, useAuth} from "../../../hooks/use-auth";

import {endpoints, useApi, useDebouncePost} from "../../../hooks/use-api";
import {Add} from "@mui/icons-material";
import CardGrid from "../../Cards";
import { useNavigate } from "react-router-dom";
import Routes from "../../../common/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const EmptyVoucher = {
  id: 0,
  voucher_detail: {
    hasRecentPrescription: false,
    eye_exam_date: "",
    prescriptionHasPdMeasurement: false,
  },
  applicant: { type: EntityTypes.applicant },
};

// const mergeVoucherAction = (vouchers, id, action) => {

//     let otherVouchers = vouchers.filter(v => v.id !== id)
//     let voucher = { ...vouchers.find(v => v.id === id) }

//     voucher.overrides = voucher.overrides || {}
//     voucher.overrides.actions = [ action, ...voucher.actions ]

//     return [ voucher, ...otherVouchers ]
// }
const SearchVoucherInner = ({ vouchersAsync, reload }) => {
  const classes = useStyles();
  const { userType } = useAuth();

  const [currentVoucher, setCurrentVoucher] = useState(EmptyVoucher);

  const [showVoucher, setShowVoucher] = useState(false);
  //?? const [voucherId, setVoucherId] = useState(false)
  const [enableEdit, setEnableEdit] = useState(false);

  const cardItemFn =
    userType === UserTypes.neweyes
      ? (voucher) => ({
          title: `Voucher # ${voucher.id}`,
          t1: voucher.name,
          d1: voucher.datetime,
          t2: voucher.applicant_name,
          t4: voucher.user_name,
          status: voucher.status,
          note: voucher.note,
        })
      : (voucher) => ({
          title: `Voucher # ${voucher.id}`,
          t1: voucher.applicant_name,
          d1: voucher.datetime,
          t2: voucher.user_name,
          status: voucher.status,
          note: voucher.note,
        });

  if (!vouchersAsync) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Fab
        aria-label="Create a new voucher application"
        color="secondary"
        variant="extended"
        style={{ position: "fixed", top: 80, right: 48, zIndex: 1150 }}
        onClick={() => {
          setCurrentVoucher(EmptyVoucher);
          setEnableEdit(true);
          setShowVoucher(true);
        }}
      >
        <Add className={classes.extendedIcon} /> New Application
      </Fab>      
      {vouchersAsync.data && (
        <div>
          {/* <div style={{ padding: '0px 12px', marginTop: 70 + 100 + 36 }}> */}
          <div style={{ padding: "0px 12px" }}>
            <CardGrid
              itemsAsync={vouchersAsync}
              itemsFn={(data) => data}
              itemfn={cardItemFn}
              itemTypeFn={() => EntityTypes.voucher}
              onSelect={(voucher) => {
                setEnableEdit(false);
                setShowVoucher(true);
                setCurrentVoucher(voucher);
              }}
            />
          </div>
          <Drawer
            anchor="right"
            open={showVoucher}
            onClose={() => {
              if (!enableEdit) setShowVoucher(false);
            }}
            classes={{ paper: "wide-drawer" }}
          >
            {enableEdit ? (
              <CreateVoucher
                individualApplication={false}
                voucher={currentVoucher}
                onClose={() => {
                  setEnableEdit(false);
                  setCurrentVoucher(EmptyVoucher);
                  setShowVoucher(false);
                }}
                onExit={(result) => {
                  if (result) {
                    // setCurrentVoucher(result) //?? did it for reload
                    setEnableEdit(false);
                    setShowVoucher(false);
                    reload(true);
                  } else {
                    setShowVoucher(false);
                  }
                }}
              />
            ) : (
              <VoucherPanel
                voucher={currentVoucher}
                userType={userType}
                onEdit={() => setEnableEdit(true)}
                onClose={() => {
                  setEnableEdit(false);
                  setShowVoucher(false);
                }}
                onAction={(id, action) => {
                  setShowVoucher(false);
                  reload();
                  //?? setVouchers(mergeVoucherAction(vouchers, id, { ...action, datetime: new Date() })) //?? COME BACK
                }}
              />
            )}
          </Drawer>
        </div>
      )}
    </div>
  );
};

const SearchVoucher = ({
  reload: reloadParent,
  agencyId = 0,
  useSearchOption = true,
}) => {
  const { searchOption, setSearchOption } = useAuth();
  const { returnAsync: vouchersAsync } = useDebouncePost(
    {
      url: endpoints.entity.voucher.search(agencyId),
      method: "post",
    },
    searchOption,
    (searchOption) => (useSearchOption ? searchOption : EmptySearchOption)
  );

  // Force reload the grid after any update
  const reload = () => {
    setSearchOption({ ...searchOption });
    if (reloadParent) reloadParent();
  };

  return <SearchVoucherInner vouchersAsync={vouchersAsync} reload={reload} />;
};

const SearchVoucherPending = ({ reload: reloadParent, url = endpoints.entity.voucher.pending_approval(), agencyId = 0 }) => {
  const [{ data, loading, error }, refetch] = useApi(
    url,
    { useCache: false }
  );
  const navigate = useNavigate();

  const reload = (newVoucher) => {
    // If it is a newVoucher then we should be on the vouchers tab to show the newly added voucher
    if (newVoucher) {
      navigate(Routes.vouchers.route);
    } else {
      refetch();
      if (reloadParent) reloadParent();
    }
  };

  return (
    <>
      <SearchVoucherInner
        vouchersAsync={{ data, loading, error }}
        reload={reload}
      />
      <InboxMessage />
    </>
  );
};

const InboxMessage = () => {
  return (
    <div style={{width: "80%"}}>
      <img src="images/VerticalPNG_HiRes.png" alt="logo" style={{ width: "200px", height: "auto", float: "right", margin: "0 15px 0 0" }} />
      <div>
        <Typography variant="h6">
          WELCOME TO NEW EYES’ APPLICATION PORTAL!
        </Typography>
        <ul>
          <li>To MAKE an application, select the purple tab (top right).</li>
          <li>There is a <strong>NON-REFUNDABLE</strong> $15 application fee for each application.  Payment may
            be made with all major credit/debit cards or pre-paid credit cards.</li>
        </ul>
        <Typography variant="h6">
          NEW EYEGLASS ORDER SITE IS LIVE*
        </Typography>
        <ul>
          <li>In response to requests for upgrades such as progressives, blue light, transitions or tints,
            we now have an <strong>order site with low-cost out-of-pocket options</strong> that individuals may
            also consider. Please note New Eyes cannot pay for upgrades.</li>
          <li>Individuals who have paid the application fee will still be able to receive single-vision or
            lined bi-focal polycarbonate prescription glasses at no additional cost, just as before.</li>
        </ul>
        <p>*PLEASE READ THE INSTRUCTIONS ON OUR <a target="_blank" href="https://shop.neweyesglasses.org/pages/voucher-discount-lookup">ORDER SITE</a>
          &nbsp;TO <strong>CONVERT THE E-VOUCHER TO A COUPON CODE. Click&nbsp;
            <a target="_blank" href="https://new-eyes.org/new-ordering">here</a> for information about our eyeglasses
            ordering site.</strong></p>
        <p>Please email info@new-eyes.org with any questions about an application.</p>
        <p>Please email our dispensers at help@neweyesglasses.org with ordering or dispensing questions.</p>
      </div>
    </div>
  );
}

export { SearchVoucherPending, SearchVoucher };
