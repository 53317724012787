import React from "react";
// import Preview from './Preview';
import { CheckBoxRow, WizardPage } from "../WizardPage";

const indCertItems = {
  items: [
    {
      id: 1,
      type: "checkbox",
      visiblefn: (_data) => true,
      label:
        "I certify that the above information is correct to the best of my knowledge.",
      property: "cert1",
      valuefn: (data) => data["cert1"],
    },
    {
      id: 2,
      type: "checkbox",
      visiblefn: (_data) => true,
      label:
        "I understand that I must pay a NON-REFUNDABLE $15 application fee for making this application.",
      style: { fontWeight: "bold", textDecoration: "underline" },
      property: "cert3",
      valuefn: (data) => data["cert3"],
    },
    {
      id: 3,
      type: "checkbox",
      visiblefn: (_data) => true,
      label:
        "I understand that if the application is approved, a New Eyes e-voucher will be emailed and used to obtain a basic pair of prescription (single-vision or bifocal) eyeglasses only through New Eyes' online dispenser.",
      property: "cert2",
      valuefn: (data) => data["cert2"],
    },
    {
      id: 4,
      type: "checkbox",
      visiblefn: (_data) => true,
      label:
        "I acknowledge that the order must be placed with great care and understand that input errors will result in incorrect lenses and/or frames, which cannot be replaced or exchanged.",
      property: "cert4",
      valuefn: (data) => data["cert4"],
    },
    {
      id: 5,
      type: "checkbox",
      visiblefn: (_data) => true,
      label:
        "When the eyeglasses are ordered, I will provide an address for delivery that is safe and secure. I understand New Eyes is not responsible if the address is incorrect or the designated mailbox cannot accept packages resulting in a lost delivery. I understand that the applicant will not be eligible for another pair of glasses if they are lost in delivery.",
      property: "cert5",
      valuefn: (data) => data["cert5"],
    },
  ],
};

const caseWorkerCertItems = {
  items: [
    {
      id: 1,
      type: "checkbox",
      visiblefn: (_data) => true,
      label:
        "I certify that the above information is correct to the best of my knowledge.",
      property: "cert1",
      valuefn: (data) => data["cert1"],
    },
    {
      id: 2,
      type: "checkbox",
      visiblefn: (_data) => true,
      label:
        "I understand my organization must arrange payment of the NON-REFUNDABLE $15 application fee for this client. (OR will be invoiced if my agency has prior approval from New Eyes).",
      style: { fontWeight: "bold", textDecoration: "underline" },
      property: "cert2",
      valuefn: (data) => data["cert2"],      
    }
  ]
};

export default ({ data, errors }) => {
  return (
      <WizardPage
        items={data.individualApplication ? indCertItems.items : caseWorkerCertItems.items}
        data={data}
        errors={errors}
        setData={(c) => c}
      />
  );
};
