import { Button, Checkbox, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { BoxRow } from "../DataEntry/StyledFields";

const Requirement = ({ onAccept }) => {
  const [proceed, setProceed] = useState(false);
  const [requireError, setRequireError] = useState("");

  return (
    <>
      <BoxRow>
        <Typography variant="h5">
          <strong>E-Voucher Application for Individuals</strong>
        </Typography>
        <Typography variant="h6">
          This application is ONLY for individuals who do NOT have a social
          worker, case-worker or other health advocate. All others must apply
          through their existing referring case-worker or social worker.
        </Typography>
      </BoxRow>
      <Box sx={{ my: 2, width: "100%" }}>
        <Typography variant="h5">
          <strong>
            Please read these requirements before you continue with the
            application:
          </strong>
        </Typography>
        <ol>
          <li>
            YOU WILL BE REQUIRED TO PAY a <strong>NON-REFUNDABLE</strong> $15 APPLICATION FEE before approval of your application.
          </li>
          <li>
            YOU MUST HAVE A CURRENT EYEGLASS PRESCRIPTION DATED WITHIN THE LAST
            24 MONTHS. If you have an older prescription, New Eyes provides
            online vision testing and renewal of prescriptions for eligible
            applicants. Eligibility depends on which state you live in and your
            age.
          </li>
          <li>
            YOU MUST HAVE YOUR PUPILLARY DISTANCE MEASUREMENT. If it is not
            included on the eyeglasses prescription (preferable) you may measure
            it yourself. Instructions can be found&nbsp;
            <a href="https://new-eyes.org/pdmeasurement">
              here
            </a>
            .
          </li>
          <li>
            YOU WILL BE REQUIRED TO UPLOAD VERIFICATION OF NEED (e.g. a photo or
            screen shot of your Snap Benefits, Unemployment Assistance, most
            recent tax return, or other confirmation of financial need).
          </li>
          <li>
            YOU WILL RECEIVE AN EMAIL IN RESPONSE TO YOUR ONLINE APPLICATION WITHIN
            2 BUSINESS DAYS. If you are approved for our program, instructions on how
            to order your glasses online will be included in the email.
          </li>
        </ol>
      </Box>

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          style={{ marginTop: "20px", marginBottom: "54px" }}
        >
          <Grid item xs={1}>
            <Checkbox
              required
              id="chkboxLabel"
              color="primary"
              checked={proceed}
              onChange={(e, checked) => {
                if (checked) setRequireError("");
                setProceed(checked);
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <label htmlFor="chkboxLabel">
              I agree to pay the <strong>NON-REFUNDABLE</strong> $15 application fee and have read the requirements to participate in the program.
              I wish to continue with the application process.
            </label>
            <Typography
              variant="error"
              color="error"
              style={{ marginTop: "12px" }}
            >
              &nbsp;&nbsp;{requireError}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <p>
              If you have questions about eligibility or the application
              process, please reach out to New Eyes at
              <br />
              <a href="mailto:info@new-eyes.org">info@new-eyes.org</a>
            </p>
          </Grid>
        </Grid>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={(e) =>
            proceed
              ? onAccept(true)
              : setRequireError("Please check this box if you want to proceed.")
          }
        >
          Continue
        </Button>
      </Grid>
    </>
  );
};

export default Requirement;
