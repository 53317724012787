import {
  differenceInHours,
  differenceInMonths,
  format,
  parseISO,
} from "date-fns";

export const displayDate = (dt) =>
  dt.length > 10
    ? format(new Date(dt), "MMM dd, yyyy")
    : format(new Date(dt + "T00:00:00"), "MMM dd, yyyy");

export const ExcelMimeType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const PdfMimeType = "application/pdf";

export const calculateAge = (dateOfBirth) => {
  try {
    let totalMonths = differenceInMonths(new Date(), parseISO(dateOfBirth));
    let months = totalMonths % 12;
    let years = (totalMonths - months) / 12;
    return months > 0
      ? `${years} years, ${months} month${months > 1 ? "s" : ""}`
      : `${years} years`;
  } catch (e) {
    return "";
  }
};

export const howLongAgo = (dt) => {
  try {
    let totalHours = Math.trunc(differenceInHours(new Date(), parseISO(dt)));
    if (totalHours < 24) {
      return `${totalHours} hours`;
    } else {
      return `${Math.trunc(totalHours / 24)} days`;
    }
  } catch (e) {
    return "";
  }
};

export const InsuranceTypes = [
  { name: "Private Health", value: "has_private_insurance" },
  { name: "Medicare", value: "has_medicare" },
  { name: "Medicaid", value: "has_medicaid" },
  { name: "Other Public Assistance", value: "has_other_assistance" },
];

export const DiscountTypes = [
  { name: "None", value: 0.00 },
  { name: "25%", value: 0.25 },
  { name: "50%", value: 0.50 },
  { name: "75%", value: 0.75 },
  { name: "Waive", value: 1.0 },
];

export const UserTypes = {
  neweyes: 1,
  agency: 2,
  caseworker: 3,
  registered: 5,
};

export const EntityTypes = {
  agency: 1,
  user: 2,
  applicant: 3,
  dispenser: 4,
  voucher: 5,
  cognito: 6,
  pendingUser: 7,
  visibly: 8,
};

export const EmptyOptions = [{ name: "", value: "" }];

export const ReportOptions = [
  { name: "Voucher - All", value: "voucherAll" },
  { name: "Voucher - Issued", value: "voucherIssued" },
  { name: "Voucher - Redeemed", value: "voucherRedeemed" },
  { name: "Voucher - Redeemed but not paid", value: "voucherRedeemedNotPaid" },
  {
    name: "Voucher - Outstanding (Issued but not redeemed)",
    value: "voucherOutstanding",
  },
  { name: "Voucher - Paid", value: "voucherPaid" },
  { name: "Voucher - Cancelled", value: "voucherCancelled" },
  { name: "Grant Report", value: "grantReport" },
  { name: "Liability Report", value: "liabilityReport" },
  // { name: 'Agency', value: 'agency' },
  // { name: 'Applicant', value: 'applicant' },
  { name: "User", value: "user" },
  { name: "Agency", value: "agency" },
  { name: "Voucher - Financial", value: "voucherFinancial" },
 // {name: "Individual Applicant Report - (How did you hear about us)", value :"individualapplicantsource"}
];

export const GenderOptions = [
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
  { name: "Other", value: "Other" },
];

export const HowFoundNewEyesOptions = [
  { name: "Google search", value: "google" },
  { name: "Facebook", value: "facebook" },
  { name: "Instagram", value: "instagram" },
  { name: "Other Web Search", value: "other-search" },
  {
    name: "Health/social services resource directory",
    value: "health-services",
  },
  { name: "Word of mouth", value: "word-of-mouth" },
  { name: "Other", value: "other" },
];

export const StateOptions = [
  { name: "", value: "" },
  { name: "Alabama", value: "AL" },
  { name: "Alaska", value: "AK" },
  { name: "Arizona", value: "AZ" },
  { name: "Arkansas", value: "AR" },
  { name: "California", value: "CA" },
  { name: "Colorado", value: "CO" },
  { name: "Connecticut", value: "CT" },
  { name: "Delaware", value: "DE" },
  { name: "District of Columbia", value: "DC" },
  { name: "Florida", value: "FL" },
  { name: "Georgia", value: "GA" },
  { name: "Hawaii", value: "HI" },
  { name: "Idaho", value: "ID" },
  { name: "Illinois", value: "IL" },
  { name: "Indiana", value: "IN" },
  { name: "Iowa", value: "IA" },
  { name: "Kansas", value: "KS" },
  { name: "Kentucky", value: "KY" },
  { name: "Louisiana", value: "LA" },
  { name: "Maine", value: "ME" },
  { name: "Maryland", value: "MD" },
  { name: "Massachusetts", value: "MA" },
  { name: "Michigan", value: "MI" },
  { name: "Minnesota", value: "MN" },
  { name: "Mississippi", value: "MS" },
  { name: "Missouri", value: "MO" },
  { name: "Montana", value: "MT" },
  { name: "Nebraska", value: "NE" },
  { name: "Nevada", value: "NV" },
  { name: "New Hampshire", value: "NH" },
  { name: "New Jersey", value: "NJ" },
  { name: "New Mexico", value: "NM" },
  { name: "New York", value: "NY" },
  { name: "North Carolina", value: "NC" },
  { name: "North Dakota", value: "ND" },
  { name: "Ohio", value: "OH" },
  { name: "Oklahoma", value: "OK" },
  { name: "Oregon", value: "OR" },
  { name: "Pennsylvania", value: "PA" },
  { name: "Puerto Rico", value: "PR" },
  { name: "Rhode Island", value: "RI" },
  { name: "South Carolina", value: "SC" },
  { name: "South Dakota", value: "SD" },
  { name: "Tennessee", value: "TN" },
  { name: "Texas", value: "TX" },
  { name: "Utah", value: "UT" },
  { name: "Vermont", value: "VT" },
  { name: "Virginia", value: "VA" },
  { name: "Washington", value: "WA" },
  { name: "West Virginia", value: "WV" },
  { name: "Wisconsin", value: "WI" },
  { name: "Wyoming", value: "WY" },
];

export const FindOptionForValue = (options, value) => {
  for (var i = 0; i < options.length; i++) {
    if (options[i].value === value) {
      return options[i];
    }
  }
  return { name: "", value: "" };
};

export const voucherStatusListForUser = {
  [UserTypes.neweyes]: ["pending_approval", "approved", "rejected", "redeemed", "paid"],
  [UserTypes.agency]: ["pending_approval", "approved", "rejected"],
  [UserTypes.caseworker]: ["pending_approval", "approved", "rejected"],
  [UserTypes.registered]: [],
};

// export const EmptyVoucherDetail = {
//   individualApplication: false,
//   hasRecentPrescription: false,
//   eye_exam_date: "",
//   prescriptionHasPdMeasurement: false,
//   canTakeVisionTest: false,
// };

export const EmptyVoucher = {
  id: 0,
  voucher_detail: {
    individualApplication: false,
    hasRecentPrescription: false,
    eye_exam_date: "",
    prescriptionHasPdMeasurement: false,
    canTakeVisionTest: false,
    isVisionTestQualifies: false,

    birth_date: "",
    state: "",

    sph_left: "",
    cyl_left: "",
    axis_left: "",
    add_left: "",
    pd_left: "",
    sph_right: "",
    cyl_right: "",
    axis_right: "",
    add_right: "",
    pd_right: "",
  },
  applicant: { id: -1, type: EntityTypes.applicant, country: "US" },
};

export const VoucherAppPages = {
  prescription: 0,
  visionTest: 1,
  preQual: 2,
  qualResult: 3,
  applicant: 4,
};

export const SystemMessages = {
  PAYMENT_INVOICE_OPTION: "Thank you. Your organization will be invoiced for the $15 application fee due. Your application has been submitted. New Eyes will review the application within 2 business days and email you further instructions.",
  PAYMENT_RECEIVED: "Thank you for your payment. Your application has been submitted. New Eyes will review the application within 2 business days and email you further instructions.",

}